import EvgmKeys from 'src/constants/locale/key/Evgm'
import Namespace from 'src/constants/locale/Namespace'
import { useTranslation } from 'cng-web-lib'

const EvgmTranslationText = () => {
    const { translate } = useTranslation([
        Namespace.UI_COMPONENT,
        Namespace.EVGM,
        Namespace.COMPANY_PREFERENCE,
        Namespace.COMMON
    ])

    let title = translate(
        Namespace.EVGM,
        EvgmKeys.TITLE
    )

    let evgmId = translate(
        Namespace.EVGM,
        EvgmKeys.EVGM_ID
    )

    let evgmStatus = translate(
        Namespace.EVGM,
        EvgmKeys.EVGM_STATUS
    )

    let carrier = translate(
        Namespace.EVGM,
        EvgmKeys.CARRIER
    )

    let containerNo = translate(
        Namespace.EVGM,
        EvgmKeys.CONTAINER_NO
    )

    let containerType = translate(
        Namespace.EVGM,
        EvgmKeys.CONTAINER_TYPE
    )

    let verifiedGrossMass = translate(
        Namespace.EVGM,
        EvgmKeys.VERIFIED_GROSS_MASS
    )

    let freightForwarder = translate(
        Namespace.EVGM,
        EvgmKeys.FREIGHT_FORWARDER
    )

    let consignor = translate(
        Namespace.EVGM,
        EvgmKeys.CONSIGNOR
    )

    let bookingNumber = translate(
        Namespace.EVGM,
        EvgmKeys.BOOKING_NUMBER
    )

    let shipperIdentifyingNumber = translate(
        Namespace.EVGM,
        EvgmKeys.SHIPPER_IDENTIFYING_NUMBER
    )

    let docId = translate(
        Namespace.EVGM,
        EvgmKeys.DOC_ID
    )

    let evgmContainerId = translate(
        Namespace.EVGM,
        EvgmKeys.EVGM_CONTAINER_ID
    )

    let lastModifiedOn = translate(
        Namespace.EVGM,
        EvgmKeys.LAST_MODIFIED_ON
    )

    //My Evgm
    let myEvgm = translate(
        Namespace.EVGM,
        EvgmKeys.MY_EVGM
    )

    let evgmFilterDate = translate(
        Namespace.EVGM,
        EvgmKeys.EVGM_FILTER_DATE
    )
    
    let lastModifiedDate = translate(
        Namespace.EVGM,
        EvgmKeys.LAST_MODIFIED_DATE
    )

    let evgmFilterAll = translate(
        Namespace.EVGM,
        EvgmKeys.EVGM_FILTER_ALL
    )

    let evgmFilterDate15 = translate(
        Namespace.EVGM,
        EvgmKeys.EVGM_FILTER_DATE_15
    )

    let evgmFilterDate30 = translate(
        Namespace.EVGM,
        EvgmKeys.EVGM_FILTER_DATE_30
    )

    let evgmFilterDate60 = translate(
        Namespace.EVGM,
        EvgmKeys.EVGM_FILTER_DATE_60
    )

    let shown = translate(
        Namespace.EVGM,
        EvgmKeys.SHOWN
    )

    //Evgm Status Header
    let evgmNewPending = translate(
        Namespace.EVGM,
        EvgmKeys.EVGM_NEW_PENDING
    )

    let evgmSubmitted = translate(
        Namespace.EVGM,
        EvgmKeys.EVGM_SUBMITTED
    )

    let evgmUpdatePending = translate(
        Namespace.EVGM,
        EvgmKeys.EVGM_UPDATE_PENDING
    )

    let evgmCancelledPending = translate(
        Namespace.EVGM,
        EvgmKeys.EVGM_CANCELLED_PENDING
    )

    let evgmCancelledSubmitted = translate(
        Namespace.EVGM,
        EvgmKeys.EVGM_CANCELLED_SUBMITTED
    )

    let evgmAccepted = translate(
        Namespace.EVGM,
        EvgmKeys.EVGM_ACCEPTED
    )

    let evgmRejected = translate(
        Namespace.EVGM,
        EvgmKeys.EVGM_REJECTED
    )

    let nothingHereYet = translate(
        Namespace.EVGM,
        EvgmKeys.NOTHING_HERE_YET
    )

    let noItemHereYet = translate(
        Namespace.EVGM,
        EvgmKeys.NO_ITEM_HERE_YET
    )

    let noItemDescShipper = translate(
        Namespace.EVGM,
        EvgmKeys.NO_ITEM_DESC_SHIPPER
    )

    let noItemDescCarrier = translate(
        Namespace.EVGM,
        EvgmKeys.NO_ITEM_DESC_CARRIER
    )

    let smthWrong = translate(
        Namespace.EVGM,
        EvgmKeys.SMTH_WRONG
    )

    let smthWrongDesc = translate(
        Namespace.EVGM,
        EvgmKeys.SMTH_WRONG_DESC
    )

    let refresh = translate(
        Namespace.EVGM, 
        EvgmKeys.REFRESH
    )

    let createEvgmTitle = translate(
        Namespace.EVGM,
        EvgmKeys.CREATE_EVGM
    )

    let stepLabelFillupDetails = translate(
        Namespace.EVGM,
        EvgmKeys.FILLUP_DETAILS
    )

    let stepLabelReviewDetails = translate(
        Namespace.EVGM,
        EvgmKeys.REVIEW_DETAILS
    )

    let stepLabelSubmit = translate(
        Namespace.EVGM,
        EvgmKeys.SUBMIT
    )

    let stepLabelFinish = translate(
        Namespace.EVGM,
        EvgmKeys.FINISH
    )

    let invoiceInformationTitle = translate(
        Namespace.EVGM,
        EvgmKeys.INVOICE_INFORMATION_TITLE
    )

    let refIdType = translate(
        Namespace.EVGM,
        EvgmKeys.REF_ID_TYPE
    )

    let refIdValue = translate(
        Namespace.EVGM,
        EvgmKeys.REF_ID_VALUE
    )

    let addRefId = translate(
        Namespace.EVGM,
        EvgmKeys.ADD_REF_ID
    )

    //Contacts
    let partyIdType = translate(
        Namespace.EVGM,
        EvgmKeys.PARTY_ID_TYPE
    )
    
    let agencyCode = translate(
        Namespace.EVGM,
        EvgmKeys.AGENCY_CODE
    )

    let contactName = translate(
        Namespace.EVGM,
        EvgmKeys.CONTACT_NAME
    )

    let contactAddress = translate(
        Namespace.EVGM,
        EvgmKeys.CONTACT_ADDRESS
    )

    let phoneNumber = translate(
        Namespace.EVGM,
        EvgmKeys.PHONE_NUMBER
    )

    let emailAddress = translate(
        Namespace.EVGM,
        EvgmKeys.EMAIL_ADDRESS
    )

    //Carrier
    let carrierInfoTitle = translate(
        Namespace.EVGM,
        EvgmKeys.CARRIER_INFO_TITLE
    )
    let carrierPartyId = translate(
        Namespace.EVGM,
        EvgmKeys.CARRIER_PARTY_ID
    )
    let carrierContactType = translate(
        Namespace.EVGM,
        EvgmKeys.CARRIER_CONTACT_TYPE
    )
    let carrierAdditional = translate(
        Namespace.EVGM,
        EvgmKeys.CARRIER_ADDITIONAL
    )

    //Shipper
    let shipperInfoTitle = translate(
        Namespace.EVGM,
        EvgmKeys.SHIPPER_INFO_TITLE
    )
    let shipperPartyId = translate(
        Namespace.EVGM,
        EvgmKeys.SHIPPER_PARTY_ID
    )
    let shipperContactType = translate(
        Namespace.EVGM,
        EvgmKeys.SHIPPER_CONTACT_TYPE
    )
    let shipperAdditional = translate(
        Namespace.EVGM,
        EvgmKeys.SHIPPER_ADDITIONAL
    )

    //Consignor
    let consignorInfoTitle = translate(
        Namespace.EVGM,
        EvgmKeys.CONSIGNOR_INFO_TITLE
    )
    let consignorPartyId = translate(
        Namespace.EVGM,
        EvgmKeys.CONSIGNOR_PARTY_ID
    )
    let consignorContactType = translate(
        Namespace.EVGM,
        EvgmKeys.CONSIGNOR_CONTACT_TYPE
    )
    let consignorAdditional = translate(
        Namespace.EVGM,
        EvgmKeys.CONSIGNOR_ADDITIONAL
    )

    //Container
    let containerDetailsTitle = translate(
        Namespace.EVGM,
        EvgmKeys.CONTAINER_DETAILS_TITLE
    )
    let containerNumber = translate(
        Namespace.EVGM,
        EvgmKeys.CONTAINER_NUMBER
    )
    let containerSizeTypeHeight = translate(
        Namespace.EVGM,
        EvgmKeys.CONTAINER_SIZE_TYPE_HEIGHT
    )
    let containerStatus = translate(
        Namespace.EVGM,
        EvgmKeys.CONTAINER_STATUS
    )

    //VGM
    let vgm = translate(
        Namespace.EVGM,
        EvgmKeys.VGM
    )
    let vgmUom = translate(
        Namespace.EVGM,
        EvgmKeys.VGM_UOM
    )
    let vgmDate = translate(
        Namespace.EVGM,
        EvgmKeys.VGM_DATE
    )

    //Container ref number
    let ctnRefNoTitle = translate(
        Namespace.EVGM,
        EvgmKeys.CTN_REF_NO_TITLE
    )
    let ctnRefNoType = translate(
        Namespace.EVGM,
        EvgmKeys.CTN_REF_NO_TYPE
    )
    let ctnRefNoNumber = translate(
        Namespace.EVGM,
        EvgmKeys.CTN_REF_NO_NUMBER
    )
    let ctnRefNoAddRow = translate(
        Namespace.EVGM,
        EvgmKeys.CTN_REF_NO_ADD_ROW
    )
    
    //Container seals info
    let ctnSealsInfoTitle = translate(
        Namespace.EVGM,
        EvgmKeys.CTN_SEALS_INFO_TITLE
    )
    let ctnSealsInfoCode = translate(
        Namespace.EVGM,
        EvgmKeys.CTN_SEALS_INFO_CODE
    )
    let ctnSealsInfoNumber = translate(
        Namespace.EVGM,
        EvgmKeys.CTN_SEALS_INFO_NUMBER
    )
    let ctnSealsAddRow = translate(
        Namespace.EVGM,
        EvgmKeys.CTN_SEALS_ADD_ROW
    )
    
    //Container location
    let ctnLocationTitle = translate(
        Namespace.EVGM,
        EvgmKeys.CTN_LOCATION_TITLE
    )
    let ctnLocationType = translate(
        Namespace.EVGM,
        EvgmKeys.CTN_LOCATION_TYPE
    )
    let ctnLocationNumber = translate(
        Namespace.EVGM,
        EvgmKeys.CTN_LOCATION_NUMBER
    )
    let ctnLocationName = translate(
        Namespace.EVGM,
        EvgmKeys.CTN_LOCATION_NAME
    )
    let ctnLocationAddRow = translate(
        Namespace.EVGM,
        EvgmKeys.CTN_LOCATION_ADD_ROW
    )
    

    let ctnDocTitle = translate(
        Namespace.EVGM,
        EvgmKeys.CTN_DOC_TITLE
    )
    let ctnDocCode = translate(
        Namespace.EVGM,
        EvgmKeys.CTN_DOC_CODE
    )
    let ctnDocId = translate(
        Namespace.EVGM,
        EvgmKeys.CTN_DOC_ID
    )
    let ctnDocDate = translate(
        Namespace.EVGM,
        EvgmKeys.CTN_DOC_DATE
    )

    let ctnDocInfoTitle = translate(
        Namespace.EVGM,
        EvgmKeys.CTN_DOC_INFO_TITLE
    )
    let ctnDocType = translate(
        Namespace.EVGM,
        EvgmKeys.CTN_DOC_TYPE
    )
    let ctnDocPartyName = translate(
        Namespace.EVGM,
        EvgmKeys.CTN_DOC_PARTY_NAME
    )
    let ctnDocPartyAddress = translate(
        Namespace.EVGM,
        EvgmKeys.CTN_DOC_PARTY_ADDRESS
    )
    let ctnDocContactType = translate(
        Namespace.EVGM,
        EvgmKeys.CTN_DOC_CONTACT_TYPE
    )
    let ctnDocContactName = translate(
        Namespace.EVGM,
        EvgmKeys.CTN_DOC_CONTACT_NAME
    )

    let ctnDocCommType = translate(
        Namespace.EVGM,
        EvgmKeys.CTN_DOC_COMM_TYPE
    )
    let ctnDocCommValue = translate(
        Namespace.EVGM,
        EvgmKeys.CTN_DOC_COMM_VALUE
    )

    let dialogDiscardEvgmContent = translate(
        Namespace.EVGM,
        EvgmKeys.DISCARD_EVGM_CONTENT
    )
    let dialogDiscardEvgmTitle = translate(
        Namespace.EVGM,
        EvgmKeys.DISCARD_EVGM_TITLE
    )

    let draftSuccessMsg = translate(
        Namespace.EVGM,
        EvgmKeys.DRAFT_SUCCESS_MSG
    )
    let backToMyEvgm = translate(
        Namespace.EVGM,
        EvgmKeys.BACK_TO_MY_EVGM
    )
    let discard = translate(
        Namespace.EVGM,
        EvgmKeys.DISCARD
    )
    let backToEdit = translate(
        Namespace.EVGM,
        EvgmKeys.BACK_TO_EDIT
    )
    let submitEvgm = translate(
        Namespace.EVGM,
        EvgmKeys.SUBMIT_EVGM
    )

    return {
        title,
        evgmId,
        evgmStatus,
        carrier,
        containerNo,
        containerType,
        verifiedGrossMass,
        freightForwarder,
        consignor,
        bookingNumber,
        shipperIdentifyingNumber,
        docId,
        evgmContainerId,
        lastModifiedOn,
        
        myEvgm,
        evgmFilterDate,
        lastModifiedDate,
        evgmFilterAll,
        evgmFilterDate15,
        evgmFilterDate30,
        evgmFilterDate60,
        shown,

        evgmNewPending,
        evgmSubmitted,
        evgmUpdatePending,
        evgmCancelledPending,
        evgmCancelledSubmitted,
        evgmAccepted,
        evgmRejected,

        nothingHereYet,
        noItemHereYet,
        noItemDescShipper,
        noItemDescCarrier,
        smthWrong,
        smthWrongDesc,
        refresh,

        createEvgmTitle,
        stepLabelFillupDetails,
        stepLabelFinish,
        stepLabelReviewDetails,
        stepLabelSubmit,

        invoiceInformationTitle,
        refIdType,
        refIdValue,
        addRefId,

        partyIdType,
        agencyCode,
        contactName,
        contactAddress,
        phoneNumber,
        emailAddress,

        carrierInfoTitle,
        carrierPartyId,
        carrierContactType,
        carrierAdditional,

        shipperInfoTitle,
        shipperPartyId,
        shipperContactType,
        shipperAdditional,

        consignorInfoTitle,
        consignorPartyId,
        consignorContactType,
        consignorAdditional,

        containerDetailsTitle,
        containerNumber,
        containerSizeTypeHeight,
        containerStatus,

        vgm,
        vgmUom,
        vgmDate,

        ctnRefNoTitle,
        ctnRefNoType,
        ctnRefNoNumber,
        ctnRefNoAddRow,

        ctnSealsInfoTitle,
        ctnSealsInfoCode,
        ctnSealsInfoNumber,
        ctnSealsAddRow,

        ctnLocationTitle,
        ctnLocationType,
        ctnLocationNumber,
        ctnLocationName,
        ctnLocationAddRow,
        
        ctnDocTitle,
        ctnDocCode,
        ctnDocId,
        ctnDocDate,

        ctnDocInfoTitle,
        ctnDocType,
        ctnDocPartyName,
        ctnDocPartyAddress,
        ctnDocContactType,
        ctnDocContactName,

        ctnDocCommType,
        ctnDocCommValue,

        dialogDiscardEvgmContent,
        dialogDiscardEvgmTitle,

        draftSuccessMsg,
        backToMyEvgm,
        discard,
        backToEdit,
        submitEvgm
    }
}

export default EvgmTranslationText
import { Yup } from 'cng-web-lib'
import ValidationMessageTranslationText from './ValidationMessageTranslationText'

function useEvgmShipperValidationSchema(){
    const validationMessageTranslation = ValidationMessageTranslationText();

    return Yup.object({
            shipperPartyName: Yup.string()
                .required(validationMessageTranslation.requiredMessage)
                .nullable(),
            shipperPartyIdType: Yup.string()
                .required(validationMessageTranslation.requiredMessage)
                .nullable(),
            shipperAgencyCode: Yup.string()
                .required(validationMessageTranslation.requiredMessage)
                .nullable(),
            shipperContactType: Yup.string().when(
                ['shipperContactName', 'shipperContactAddress', 'shipperPhoneNumber', 'shipperEmailAddress'],
                {
                    is: (shipperContactName, shipperContactAddress, shipperPhoneNumber, shipperEmailAddress) =>
                    shipperContactName || shipperContactAddress || shipperPhoneNumber || shipperEmailAddress,
                    then: Yup.string().required(validationMessageTranslation.requiredMessage),
                    otherwise: Yup.string().nullable(),
                }
            ),
        }
    )
}

export default useEvgmShipperValidationSchema
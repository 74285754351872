import { Box, Paper } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { components, useServices } from 'cng-web-lib'
import { useHistory, useLocation} from 'react-router-dom'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import CngBackdrop from 'src/views/vesselschedule/searchschedule/cngcomponent/CngBackDrop'
import CommonUtils from 'src/views/common/utils/Utils'
import pathMap from 'src/paths/PathMap_Evgm'
import GeneralStepperComponent from 'src/views/common/ui/GeneralStepperComponent'
import ConfirmDialog from 'src/components/dialog/ConfirmDialog'
import EvgmTranslationText from '../shared/EvgmTranslationText'
import SubmittedEvgmButtonComponent from '../components/SubmittedEvgmButtonComponent'
import DraftEvgmButtonComponent from '../components/DraftEvgmButtonComponent'
import ViewEvgmRefIdComponent from '../components/ViewEvgmRefIdComponent'
import ViewEvgmShipperComponent from '../components/ViewEvgmShipperComponent'
import ViewEvgmCarrierComponent from '../components/ViewEvgmCarrierComponent'
import ViewEvgmConsignorComponent from '../components/ViewEvgmConsignorComponent'
import ViewEvgmContainerDetailsComponent from '../components/ViewEvgmContainerDetailsComponent'
import ViewEvgmContainerDocumentComponent from '../components/ViewEvgmContainerDocumentComponent'
import EVGMApiUrls from 'src/apiUrls/EvgmApiUrls'

const DEFAULT_INITIAL_VALUES = Object.freeze({
    evgmId: '',
    isFirstAction: false
})

const FORMIK_PROPS = {
    initialValues: { ...DEFAULT_INITIAL_VALUES }
}

const {
    table: { useDefaultNotification },
    CngGridItem
} = components

const ReviewEvgmButtonLayout = ({ isSubmitted, onEdit, onDiscard, onSubmitEvgm, redirectToMyEvgm }) => {

    if(isSubmitted){
        return(
            <SubmittedEvgmButtonComponent
                buttonAction = {redirectToMyEvgm}
            />
        )
    }else{
        return(
            <DraftEvgmButtonComponent
                onEdit={onEdit}
                onDiscard={onDiscard}
                onSubmitEvgm={onSubmitEvgm}
            />
        )
    }
}

function FormFields({
    disabled, 
    showNotification
}){

    const evgmTranslatedTextsObject = EvgmTranslationText()
    const uiTranslatedTextsObject = CalistaUiComponentTranslationText()

    const { createRecord } = useServices()
    const history = useHistory()
    const location = useLocation()
    const data = location.state.data
    const [loading, setLoading] = useState(false)
    const prevMenu = location.state.prevMenu
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
    const [permitStep,] = useState(1)
    const isSubmitted = location.state.isSubmitted || false

    const [carrier, setCarrier] = useState([])
    const [shipper, setShipper] = useState([])
    const [consignor, setConsignor] = useState([])

    const [container, setContainer] = useState([])

    const { success: showSuccessNotification, error: showErrorNotification } =
        useDefaultNotification(showNotification)

    function onError(error) {
        console.log('connection-request error', error.message)
        setLoading(false)
        showErrorNotification(CommonUtils.getErrorMessage(error))
    }

    const onEdit = () =>{
        console.log("Back to edit")
        history.push({
            pathname: pathMap.CREATE_NEW_EVGM,
            state: {
                data: {
                    ...data
                },
                isEdit: true
            },
            prevMenu,
        })
    }

    const onDiscard = () => {
        setConfirmDialogOpen(true)
    }

    const redirectToMyEvgm = () =>{
        history.push({
            pathname: pathMap.MY_EVGM
        })
    }

    function onSubmitEvgm(){
        setLoading(true)
        console.log('submit evgm')

        proceedSubmit(data)
    }

    function proceedSubmit(data){
        console.log('proceedSubmit', data)

        let url = EVGMApiUrls.SUBMIT

        function onSuccess(response){
            console.log('connection-request onSuccess', response)

            if (response.errorMessages != null) {
                showErrorNotification(response.errorMessages)
                setLoading(false)
            }else{
                showSuccessNotification('Evgm created successfully')
                setLoading(false)

                redirectToMyEvgm()
            }
        }

        createRecord.execute(
            url,
            data,
            onSuccess,
            onError
        )
    }

    const neweVGMSteps = [
        evgmTranslatedTextsObject.stepLabelFillupDetails,
        evgmTranslatedTextsObject.stepLabelReviewDetails,
        evgmTranslatedTextsObject.stepLabelFinish
    ]

    useEffect(() => {
        console.log('Review PERMIT EVGM DATA:', data)

        data.parties.forEach((party) => {
            const {role} = party

            if (role === 'CA') {
                setCarrier([party])
            } else if (role == 'CZ') {
                setConsignor([party])
            } else if (role == 'FW') {
                setShipper([party])
            }
        })

        setContainer(data.evgmContainer)
    },[])


    return(
        <Box>
            <CngGridItem xs={12} sm={9}>
                <CngBackdrop loading={loading}/>
            </CngGridItem>
            <Paper>
                <Box alignItems='center' pr={15} pl={15}>
                    <GeneralStepperComponent
                        steps={neweVGMSteps}
                        activeStep={permitStep}
                    />
                </Box>

                {/*Sections starts here*/}
                <Box p={5}>
                    <CngGridItem xs={12} sm={12}>
                        <ViewEvgmRefIdComponent.FormBody
                            refIdData = {data}
                        />
                    </CngGridItem>
                </Box>

                <Box p={5}>
                    <CngGridItem xs={12} sm={12}>
                        <ViewEvgmCarrierComponent.FormBody
                            carrierData = {carrier}
                        />
                    </CngGridItem>
                </Box>

                <Box p={5}>
                    <CngGridItem xs={12} sm={12}>
                        <ViewEvgmShipperComponent.FormBody
                            shipperData = {shipper}
                        />
                    </CngGridItem>
                </Box>

                <Box p={5}>
                    <CngGridItem xs={12} sm={12}>
                        <ViewEvgmConsignorComponent.FormBody
                            consignorData = {consignor}
                        />
                    </CngGridItem>
                </Box>

                <Box p={5}>
                    <CngGridItem xs={12} sm={12}>
                        <ViewEvgmContainerDetailsComponent.FormBody
                            containerData = {container}
                        />
                    </CngGridItem>
                </Box>

                <Box p={5}>
                    <CngGridItem xs={12} sm={12}>
                        <ViewEvgmContainerDocumentComponent.FormBody
                            containerDocData = {container.containerDocument}
                        />
                    </CngGridItem>
                </Box>

            </Paper>

            <Paper className='sticky-footer'>
                <Box alignItems='center'>
                    <ReviewEvgmButtonLayout
                        isSubmitted={isSubmitted}
                        onEdit={onEdit} 
                        onDiscard={onDiscard}
                        onSubmitEvgm={onSubmitEvgm}
                        redirectToMyEvgm={redirectToMyEvgm}
                    />
                </Box>
            </Paper>

            <ConfirmDialog
                isConfirmDialogOpen={confirmDialogOpen}
                closeDialog={() => setConfirmDialogOpen(false)}
                confirmDialog={redirectToMyEvgm}
                content={evgmTranslatedTextsObject.dialogDiscardEvgmContent}
                okMsg={uiTranslatedTextsObject.ok}
                cancelMsg={uiTranslatedTextsObject.cancel}
                title={evgmTranslatedTextsObject.dialogDiscardEvgmTitle}
            />
        </Box>
    )

}

const ReviewEvgmFormProperties = Object.freeze({
    formikProps: FORMIK_PROPS,
    FormFields: FormFields
})

export default ReviewEvgmFormProperties
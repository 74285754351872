import { Yup } from 'cng-web-lib'
import ValidationMessageTranslationText from './ValidationMessageTranslationText'

function useEvgmContainerDetailsValidationSchema(){
    const validationMessageTranslation = ValidationMessageTranslationText();

    return Yup.object({
        evgmContainer: Yup.object().shape({
            containerNo: Yup.string()
              .required(validationMessageTranslation.requiredMessage)
              .nullable(),
            containerTypeSize: Yup.string()
              .required(validationMessageTranslation.requiredMessage)
              .nullable(),
            fullEmptyIndicator: Yup.string()
              .required(validationMessageTranslation.requiredMessage)
              .nullable(),
            vgm: Yup.number()
              .required(validationMessageTranslation.requiredMessage)
              .nullable(),
            vgmUom: Yup.string()
              .required(validationMessageTranslation.requiredMessage)
              .nullable(),
            vgmDate: Yup.date()
              .required(validationMessageTranslation.requiredMessage)
              .nullable(),
            references: Yup.array().of(
              Yup.object().shape({
                type: Yup.string()
                  .required(validationMessageTranslation.requiredMessage)
                  .nullable(),
                code: Yup.string()
                  .required(validationMessageTranslation.requiredMessage)
                  .nullable(),
              })
            ),
            containerSeals: Yup.array().of(
              Yup.object().shape({
                sealNo: Yup.string()
                  .required(validationMessageTranslation.requiredMessage)
                  .nullable(),
                sealIssuerCode: Yup.string()
                  .required(validationMessageTranslation.requiredMessage)
                  .nullable(),
              })
            ),
            containerLocations: Yup.array().of(
              Yup.object().shape({
                typeCode: Yup.string()
                  .required(validationMessageTranslation.requiredMessage)
                  .nullable(),
                locCode: Yup.string()
                  .required(validationMessageTranslation.requiredMessage)
                  .nullable(),
                locName: Yup.string()
                  .required(validationMessageTranslation.requiredMessage)
                  .nullable(),
              })
            ),
            containerDocument: Yup.object().shape({
              docCode: Yup.string()
                .required(validationMessageTranslation.requiredMessage)
                .nullable(),
              docId: Yup.string()
                .required(validationMessageTranslation.requiredMessage)
                .nullable(),
              issueDate: Yup.date()
                .required(validationMessageTranslation.requiredMessage)
                .nullable(),

              docInformation: Yup.array().of(
                Yup.object().shape({
                  infoType: Yup.string()
                    .required(validationMessageTranslation.requiredMessage)
                    .nullable(),
                  
                  contactInformation: Yup.array().of(
                    Yup.object().shape({
                      contactType: Yup.string()
                        .nullable(),
                      contactName: Yup.string()
                        .when('contactType', {
                          is: (contactType) => contactType && contactType.length > 0,
                          then: Yup.string().required(validationMessageTranslation.requiredMessage),
                          otherwise: Yup.string().nullable(),
                      }),
                      
                      contactInformation: Yup.array().of(
                        Yup.object().shape({
                          commType: Yup.string()
                            .nullable(),
                          commNo: Yup.string()
                            .when('commType', {
                              is: (commType) => commType && commType.length > 0,
                              then: Yup.string().required(validationMessageTranslation.requiredMessage),
                              otherwise: Yup.string().nullable(),
                          }),
                        })

                      ),
                    })
                  ),
                })
              ),

            }),
          }),
    })
}

export default useEvgmContainerDetailsValidationSchema
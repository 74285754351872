import { Yup } from 'cng-web-lib'
import ValidationMessageTranslationText from './ValidationMessageTranslationText'

function useEvgmCarrierValidationSchema(){
    const validationMessageTranslation = ValidationMessageTranslationText();

    return Yup.object({
            carrierPartyIdCode: Yup.string()
                .required(validationMessageTranslation.requiredMessage)
                .nullable(),
            carrierPartyIdType: Yup.string()
                .required(validationMessageTranslation.requiredMessage)
                .nullable(),
            carrierAgencyCode: Yup.string()
                .required(validationMessageTranslation.requiredMessage)
                .nullable(),
            carrierContactType: Yup.string().when(
                ['carrierContactName', 'carrierContactAddress', 'carrierPhoneNumber', 'carrierEmailAddress'],
                {
                    is: (carrierContactName, carrierContactAddress, carrierPhoneNumber, carrierEmailAddress) =>
                    carrierContactName || carrierContactAddress || carrierPhoneNumber || carrierEmailAddress,
                    then: Yup.string().required(validationMessageTranslation.requiredMessage),
                    otherwise: Yup.string().nullable(),
                }
            ),
        }
    )
}

export default useEvgmCarrierValidationSchema
import CngAdminApiUrls from 'src/apiUrls/CngAdminApiUrls.js'
import EvgmApiUrls from 'src/apiUrls/EvgmApiUrls.js'

function fetchEvgmList(fetchRecords, searchCriteria, onSuccess, onError) {
    console.log('gettingEvgmList')
    console.log(searchCriteria)
    fetchRecords.execute(
        EvgmApiUrls.EVGM_LIST,
        {
            customData: searchCriteria
        },
        (data) => {
            console.log('Evgm is back!')
            console.log('Evgm data: ' + data)
            onSuccess(data)
        },
        (error) => {
            onError(error)
        }
    )
}

function fetchSortingType(fetchRecords, onSuccess, onError) {
    fetchRecords.execute(
        EvgmApiUrls.SORTING_TYPE,
        {},
        (data) => {
            console.log('Sort type is back!')
            console.log(data)
            onSuccess(data)
        },
        (error) => {
            onError(error)
        }
    )
}

function fetchStatusCodeAndCount(fetchRecords, onSuccess, onError) {
    fetchRecords.execute(
        EvgmApiUrls.EVGM_STATUS_LIST,
        {},
        (data) => {
            console.log('Status list is back! ' + data.pendingStatusList.length)
            console.log(data)
            onSuccess(data)
        },
        (error) => {
            onError(error)
        } 
    )
}

function fetchPartyId(fetchRecords, searchCriteria, onSuccess, onError) {
    console.log('FetchingPartyIdFromAPI')
    console.log(searchCriteria)
    fetchRecords.execute(
        EvgmApiUrls.PARTY_LIST_EVGM_COUNT,
        {
            customData: searchCriteria
        },
        (data) => {
            console.log('party is back')
            console.log(data)
            onSuccess(data)
        },
        (error) => {
            onError(error)
        }
    )
}

function fetchAuditTrailListing(fetchRecords, keyReferenceNo, onSuccess) {
    console.log('fetchAuditTrailListing')
    console.log(keyReferenceNo)
    fetchRecords.execute(
      CngAdminApiUrls.AUDIT_TRAIL_LISTING,
      {
        filters: [
          {
            field: 'auditModuleCode',
            operator: 'EQUAL',
            value: 'CLOGS_EDI'
          },
          {
            field: 'keyReferenceNo',
            operator: 'EQUAL',
            value: keyReferenceNo
          }
        ]
      },
      (data) => {
        console.log('fetchAuditTrailListing back')
        console.log(data)
        onSuccess(data.content)
      },
      (error) => {
        console.log(error)
      }
    )
}

function fetchAuditTrailCompare(fetchRecords, versions, onSuccess) {
    console.log('fetchAuditTrailCompare')
  
    fetchRecords.execute(
      CngAdminApiUrls.AUDIT_TRAIL_COMPARE,
      {
        customData: versions
      },
      (data) => {
        console.log('fetchAuditTrailCompare back')
        console.log(data)
        onSuccess(data)
      },
      (error) => {
        console.log(error)
      }
    )
}

function fetchParties(fetchRecords, onSuccess) {
    console.log('fetchAuditTrailCompare')
  
    fetchRecords.execute(
      `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/party/get`,
      {},
      (data) => {
        console.log('fetchParties back')
        console.log(data)
        onSuccess(data)
      },
      (error) => {
        console.log(error)
      }
    )
}

function fetchUsersWithCriteria(fetchRecords, userCriteria, onSuccess) {
    console.log('fetchAuditTrailCompare')
  
    fetchRecords.execute(
      `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/user/detail/get`,
      {
        customData: {},
        filters: [
          {
            field: 'id',
            operator: 'IN',
            value: userCriteria
          }
        ]
      },
      (data) => {
        console.log('fetchUsers back')
        console.log(data)
        onSuccess(data)
      },
      (error) => {
        console.log(error)
      }
    )
}

const EvgmApi = Object.freeze({
    fetchEvgmList,
    fetchSortingType,
    fetchStatusCodeAndCount,
    fetchPartyId,
    fetchAuditTrailListing,
    fetchAuditTrailCompare,
    fetchParties,
    fetchUsersWithCriteria
})

export default EvgmApi
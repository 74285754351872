import { useHistory } from 'react-router-dom'

import MyEvgmFormProperties from './MyEvgmFormProperties'
import React from 'react'
import { components } from 'cng-web-lib'
import pathMap from 'src/paths/PathMap_Evgm'

const {
    form: { CngViewForm }
} = components

function MyEvgmPage({ showNotification }) {
    const history = useHistory()

    function onSubmit(data) {
        console.log('submit!', data)

        history.push('/')
        history.replace({ pathname: pathMap.MY_EVGM, state: data })
    }

    return (
        // use CngViewForm here for customizable button set
        <CngViewForm
            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
            history={history}
            showNotification={showNotification}
            bodySection={
                <MyEvgmFormProperties.FormFields
                    disabled={false}
                    showNotification={showNotification}
                />
            }
            formikProps={{
                ...MyEvgmFormProperties.formikProps,
                onSubmit: onSubmit
            }}
            // toClientDataFormat={PollingFormProperties.toClientDataFormat}
            // toServerDataFormat={PollingFormProperties.toServerDataFormat}
        />
    )
}

export default MyEvgmPage
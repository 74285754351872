import { useHistory } from 'react-router-dom'
import React from 'react'
import { components } from 'cng-web-lib'
import ReviewEvgmFormProperties from './ReviewEvgmFormProperties'

const {
    form: { CngViewForm }
} = components

function ReviewEvgmPage({showNotification}){
    const history = useHistory()

    function onSubmit(data) {
        console.log('submit', data)
    }

    return(
        <CngViewForm
            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
            history={history}
            showNotification={showNotification}
            bodySection= {
                <ReviewEvgmFormProperties.FormFields
                    disabled={false}
                    showNotification={showNotification}
                />
            }
            formikProps={{
                ...ReviewEvgmFormProperties.formikProps,
                onSubmit: onSubmit
            }}
        />
    )
}

export default ReviewEvgmPage
const VALUE_ALL = "ALL"

export default class EvgmApiParser {

    static parseSortingType(data) {
        let tempList = []
        data.content.map(v => {
            let temp = {
                text: v.sortDesc,
                value: v.sortCode
            }
            tempList.push(temp)
        })
        return tempList
    }

    static parseStatusCodeAndCount(data) {
        console.log('Status Code and Count', data)
        let pendingList = data.pendingStatusList.map((v) => ({ ...v, checked: true, eligible: true }))
        if (pendingList[0] !== undefined) {
            let newRecord = { ...pendingList[0] }
            newRecord.statusDesc = VALUE_ALL
    
            pendingList = [...pendingList, newRecord]
    
            console.log(pendingList)
        }
    
        let processedList = data.processedStatusList.map((v) => ({ ...v, checked: true, eligible: true }))
        if (processedList[0] !== undefined) {
            let newRecord = { ...processedList[0] }
            newRecord.statusDesc = VALUE_ALL
    
            processedList = [...processedList, newRecord]
    
            console.log(processedList)
        }
        return {...data, pendingList, processedList}
    }

    static parsePartyId(data) {
        return data.content.map((v) => ({ ...v, checked: true, eligible: true }))
    }
}


const Page = {
    EvgmDetail: 'EvgmDetail',
    EvgmList: 'EvgmList',
    CreateEvgm: 'CreateEvgm',
    ReviewEvgm: 'ReviewEvgm'
}

const Menus ={
    createEvgm: 'createEvgm',
    reviewEvgm: 'reviewEvgm'
}

const Action = {
    createEvgm: 'createEvgm',
    copy: 'copy',
}

const EvgmStatus = [
    {
        id: 'NEW',
        name: 'New VGM created',
        color: '#5E81F433',
        actions: [Action.copy]
    },
    {
        id: 'SMT',
        name: 'VGM Pending Acceptance',
        color: '#9698D64D',
        actions: [Action.copy]
    },
    {
        id: 'UPDATE',
        name: 'VGM Pending Update',
        color: '#5E81F433',
        actions: [Action.copy]
    },
    {
        id: 'CNL',
        name: 'VGM Cancellation Received',
        color: '#1C1D211A',
        actions: [Action.copy]
    },
    {
        id: 'CSM',
        name: 'VGM Pending Cancellation',
        color: '#1C1D211A',
        actions: [Action.copy]
    },
    {
        id: 'ACT',
        name: 'VGM Accepted',
        color: '#7CE7AC4D',
        actions: [Action.copy]
    },
    {
        id: 'RJT',
        name: 'VGM Rejected',
        color: '#FF808B4D',
        actions: [Action.copy]
    },
    {
        id: 'DRAFT',
        name: 'VGM Draft',
        color: '#8996AF41',
        actions: [Action.copy]
    }
]

function getEvgmStatusById(id) {
    return EvgmStatus.find((b) => b.id == id)
}

function getEvgmStatusByName(name) {
    return EvgmStatus.find((b) => b.name.toUpperCase() == name.toUpperCase())
}

function isEmpty(obj) {
    return Object.keys(obj).length === 0
}

const Utils = Object.freeze({
    Action,
    Page,
    EvgmStatus,
    getEvgmStatusById,
    getEvgmStatusByName,
    isEmpty,
    Menus
})

export default Utils
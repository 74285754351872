import { Yup } from 'cng-web-lib'
import ValidationMessageTranslationText from './ValidationMessageTranslationText'

function useEvgmRefValidationSchema(){
    const validationMessageTranslation = ValidationMessageTranslationText();

    return Yup.object({
            references: Yup.array(
                Yup.object({
                    refType: Yup.string()
                        .nullable(),
                    refCode: Yup.string()
                        .when('refType', {
                            is: (refType) => refType && refType.length > 0,
                            then: Yup.string().required(validationMessageTranslation.requiredMessage),
                            otherwise: Yup.string().nullable(),
                        }),
                })
            )
        }
    )
}

export default useEvgmRefValidationSchema
import { Yup } from 'cng-web-lib'
import ValidationMessageTranslationText from './ValidationMessageTranslationText'

function useEvgmConsignorValidationSchema(){
    const validationMessageTranslation = ValidationMessageTranslationText();

    return Yup.object({
            consignorPartyName: Yup.string()
                .required(validationMessageTranslation.requiredMessage)
                .nullable(),
            consignorPartyIdType: Yup.string()
                .required(validationMessageTranslation.requiredMessage)
                .nullable(),
            consignorAgencyCode: Yup.string()
                .required(validationMessageTranslation.requiredMessage)
                .nullable(),
            consignorContactType: Yup.string().when(
                ['consignorContactName', 'consignorContactAddress', 'consignorPhoneNumber', 'consignorEmailAddress'],
                {
                    is: (consignorContactName, consignorContactAddress, consignorPhoneNumber, consignorEmailAddress) =>
                    consignorContactName || consignorContactAddress || consignorPhoneNumber || consignorEmailAddress,
                    then: Yup.string().required(validationMessageTranslation.requiredMessage),
                    otherwise: Yup.string().nullable(),
                }
            ),
        }
    )
}

export default useEvgmConsignorValidationSchema
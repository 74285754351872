import * as CalistaConstant from 'src/constants/CalistaConstant'

import { Box, Card, CardContent, Fab, Grid, Toolbar } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Yup, components, useServices } from 'cng-web-lib'

import Api from '../../evgm/shared/api'
import ApiParser from '../../evgm/shared/apiParser'
import EvgmListComponent from '../../evgm/components/EvgmListComponents'
import EvgmListNoResult from '../../evgm/components/EvgmListNoResult'
import EvgmListPageError from '../../evgm/components/EvgmListPageError'
import ButtonTabComponent from '../components/ButtonTabComponent'
import CngBackdrop from 'src/views/vesselschedule/searchschedule/cngcomponent/CngBackDrop'
import FilterPanelComponent from '../../evgm/components/FilterPanelComponent'
import FreeTextSearchComponent from '../../evgm/components/FreeTextSearchComponent'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import ScrollTop from '../../evgm/components/ScrollTop'
import Utils from 'src/views/common/utils/Utils'

const {
    form: {
      adapter: {
        useFormAdapter:{ useField, useFormikContext }
      },
      field: { CngTextField, CngDateField }
    },
    CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
    dateRange: 'all',
    pendingClicked: true,
    filterText: '',
    sortCode: '',
    pendingStatusCount: 0,
    processedStatusCount: 0,
    statusCodes: [
      CalistaConstant.EVGM_STATUS_NEW_VGM_CREATED,
      CalistaConstant.EVGM_STATUS_PENDING_UPDATE,
      CalistaConstant.EVGM_STATUS_PENDING_CANCELLATION,
      CalistaConstant.EVGM_STATUS_PENDING_ACCEPTANCE,
      CalistaConstant.EVGM_STATUS_DRAFT
    ],
    status: {},
    partyIds: [],
    carriers: {},
    statusTabChanged: true
})

function DEFAULT_VALIDATION_SCHEMA(translate) {
    return Yup.object({})
}

const FORMIK_PROPS = {
    initialValues: { ...DEFAULT_INITIAL_VALUES },
    makeValidationSchema: DEFAULT_VALIDATION_SCHEMA
}

function FormFields({ disabled, showNotification, shouldHideMap }) {
    const { fetchRecords } = useServices()

    const [statusCodesField, , { setValue: setStatusCodesField }] =
      useField('statusCodes')
    const [dateRangeField, , { setValue: setDateRangeField }] =
      useField('dateRange')
    const [statusField, , { setValue: setStatusField }] = useField('status')
    const [sortCodeField, , { setValue: setSortCodeField }] = useField('sortCode')
    const [, , { setValue: setSortByListField }] = useField('sortByList')
    const [pendingClickedField, , { setValue: setPendingClickedField }] =
      useField('pendingClicked')
    const [filterTextField, , { setValue: setFilterTextField }] =
      useField('filterText')
    const [, , { setValue: setPendingStatusCountField }] =
      useField('pendingStatusCount')
    const [, , { setValue: setProcessedStatusCountField }] = useField(
      'processedStatusCount'
    )
    const [carriersField, , { setValue: setCarriersField }] = useField('carriers')
    const [partyIdsField, , { setValue: setPartyIdsField }] = useField('partyIds')
    const [statusTabChangedField, , { setValue: setStatusTabChangedField }] =
      useField('statusTabChanged')

    const pendingStatusCode = [
        CalistaConstant.EVGM_STATUS_NEW_VGM_CREATED,
        CalistaConstant.EVGM_STATUS_PENDING_UPDATE,
        CalistaConstant.EVGM_STATUS_PENDING_CANCELLATION,
        CalistaConstant.EVGM_STATUS_PENDING_ACCEPTANCE,
        CalistaConstant.EVGM_STATUS_DRAFT
    ] //should be loaded from api

    const processedStatusCode = [
        CalistaConstant.EVGM_STATUS_ACCEPTED,
        CalistaConstant.EVGM_STATUS_REJECTED,
        CalistaConstant.EVGM_STATUS_CANCELLATION_RECEIVED
    ]

    const defaultDateRange = 'all' //default value to query via API
    const VALUE_ALL = 'ALL'

    const [, setDateRange] = useState()
    const [sortByList, setSortByList] = useState()
    const [, setFilterText] = useState()
    const [evgmList, setEvgmList] = useState([])
  
    const [loading, setLoading] = useState(true)
    const [shownCount, setShownCount] = useState(0)

    const clickPending = () => {
        console.log('clickPending')
        setPendingClicked(true)
        setPendingClickedField(true)
        setStatusCodesField(pendingStatusCode)
        setStatus(pendingStatusList)
        setStatusField(pendingStatusList)
        setStatusTabChangedField(true)
    
        manualRefresh()
    }

    const clickProcessed = () => {
        console.log('click processed')
        setPendingClicked(false)
        setPendingClickedField(false)
        setStatusCodesField(processedStatusCode)
        setStatus(processedStatusList)
        setStatusField(processedStatusList)
        setStatusTabChangedField(true)
    
        manualRefresh()
    }

    const handleSortByChange = (e) => {
        if (e.target.value != null && e.target.value != 0) {
          setSortCodeField(e.target.value)
          setStatusTabChangedField(false)
    
          manualRefresh()
        }
    }

    const handleClearFilterAndTextButtonClick = () => {
        console.log('handleClearFilterAndTextButtonClick')
    
        setFilterText('')
        setFilterTextField('')
        handleClearAllButtonClick()
    }

    const handleClearAllButtonClick = () => {
        console.log('handleClearAllButtonClick')
    
        setDateRange(defaultDateRange)
        setDateRangeField(defaultDateRange)
        let newStatus = [...status]
        newStatus = newStatus.map((item) => ({
          ...item,
          checked: true
        }))
    
        console.log(newStatus)
        setStatus(newStatus)
        setStatusField(newStatus)
    
        let newCarriers = [...carriers]
        newCarriers = newCarriers.map((item) => ({
          ...item,
          checked: true
        }))
    
        setCarriers(newCarriers)
        setCarriersField(newCarriers)
    
        let newStatusCode = getNewStatuCode(newStatus)
        console.log(newStatusCode)
    
        setStatusCodesField(newStatusCode)
    
        setStatusTabChangedField(true) //force carrier list load from api
    
        manualRefresh()
    }

    const handleFilterTextChange = (e) => {
        console.log(e.target)
        setFilterText(e.target.value)
        setFilterTextField(e.target.value)
    }

    const handelFreeTextSearch = () => {
        setStatusTabChangedField(true)
        manualRefresh()
    }

    const onStatusCheckBoxChange =
    (index) =>
    ({ target: { checked } }) => {
      console.log('handleStatusCheckBoxChange')
      let newStatus = [...status]
      const currentStatus = status[index]
      if (currentStatus.statusDesc !== VALUE_ALL) {
        const allStatus = status[status.length - 1]

        newStatus[index] = { ...currentStatus, checked: !currentStatus.checked }
        newStatus[status.length - 1] = { ...allStatus, checked: false }
      } else {
        newStatus = newStatus.map((item) => ({
          ...item,
          checked: !currentStatus.checked
        }))
      }

      setStatus(newStatus)
      setStatusField(newStatus)
      console.log(newStatus)

      //get new status code and list
      let newStatusCode = getNewStatuCode(newStatus)
      console.log(newStatusCode)

      setStatusCodesField(newStatusCode)
      setStatusTabChangedField(true) //force carrier list load from api

      manualRefresh()
    }

    function getNewStatuCode(newStatus) {
        let temp = []
        newStatus.filter((v) => v.checked).map((v) => temp.push(v.statusCode))
    
        return temp
    }

    const dateRangeChange = (e) => {
        console.log(e.target.defaultValue)
        setStatusTabChangedField(true)
        setDateRange(e.target.defaultValue)
        setDateRangeField(e.target.defaultValue)
    
        manualRefresh()
    }

    const onCarrierCheckBoxChange =
    (index) =>
    ({ target: { checked } }) => {
      console.log('onCarrierCheckBoxChange ' + index)
      let newCarriers = [...carriers]
      const carrier = carriers[index]
      console.log(carrier.partyId)
      if (carrier.nameOth !== VALUE_ALL) {
        const allCarrier = carriers[carriers.length - 1]

        newCarriers[index] = { ...carrier, checked: !carrier.checked }
        newCarriers[carriers.length - 1] = { ...allCarrier, checked: false }
      } else {
        newCarriers = newCarriers.map((item) => ({
          ...item,
          checked: !carrier.checked
        }))
      }

      setCarriers(newCarriers)
      setCarriersField(newCarriers)

      let temp = []
      newCarriers.filter((v) => v.checked).forEach((v) => temp.push(v.partyId))

      console.log(temp)

      setPartyIdsField(temp)
      setStatusTabChangedField(false)

      manualRefresh()
    }

    let searchCriteria = {
        filterText: filterTextField.value,
        dateRange: dateRangeField.value,
        statusCodes: statusCodesField.value,
        partyIds: partyIdsField.value,
        sortCode: Utils.isEmptyString(sortCodeField.value) ? 1 : sortCodeField.value
    }

    const [carriers, setCarriers] = useState([])

    const [status, setStatus] = useState([])
    const [pendingStatusList, setPendingStatusList] = useState([])
    const [processedStatusList, setProcessedStatusList] = useState([])
    const [pendingCount, setPendingCount] = useState(0)
    const [processedCount, setProcessedCount] = useState(0)
    const [pendingClicked, setPendingClicked] = useState()
    const [loadingCriteria, setLoadingCriteria] = useState()
    const [pageError, setPageError] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const manualRefresh = () => {
        setRefresh(!refresh)
        setLoading(true)
        setEvgmList([])
        setShownCount(0)
    }

    useEffect(() => {
        console.log(statusCodesField)
    
        setPageError(false)
    
        setPendingClicked(pendingClickedField.value)
    
        setSortCodeField(
          sortCodeField.value == null || sortCodeField.value == ''
            ? 1
            : sortCodeField.value
        )
    
        if (sortCodeField.value == null || sortCodeField.value == '') {
          //fetch sort code when the page is first loaded, the result will remain static
          getSortingType()
        }
    
        //get status code and count
        fetchStatusCodeAndCountFromAPI()
    }, [refresh])

    function populatePartyId(dat) {
        let FromApi = ApiParser.parsePartyId(dat)
        if (FromApi[0] !== undefined) {
          let newRecord = { ...FromApi[0] }
          newRecord.nameOth = 'ALL'
          newRecord.evgmCount = FromApi.reduce((a, b) => a + b.evgmCount, 0)
          FromApi = [...FromApi, newRecord]
    
          console.log(FromApi)
    
          let partyIdsForSearch = getPartyIdForEvgmList(dat.content)
          console.log(partyIdsForSearch)
          getEvgmList()
        } else {
          setLoading(false)
          setPartyIdsField(
            Utils.isEmptyString(partyIdsField.value) ? [] : partyIdsField.value
          )
        }
    
        if (reloadCarrierCheckBoxNeeded()) {
          setCarriers(FromApi)
          setCarriersField(FromApi)
        } else {
          setCarriers(carriersField.value)
          //  setCarriersField(carriersField.value)
        }
    }

    function fetchPartyIdFromAPI() {
        Api.fetchPartyId(fetchRecords, searchCriteria, populatePartyId, onError)
    }

    function reloadCarrierCheckBoxNeeded() {
        return statusTabChangedField.value
    }

    function getPartyIdForEvgmList(carrierList) {
        console.log('getPartyIdForEvgmList')
        let partyIdss = []
        if (reloadCarrierCheckBoxNeeded()) {
          console.log('loading party id from api')
    
          carrierList.map((v) => {
            console.log(v.partyId)
            partyIdss.push(v.partyId)
          })
    
          searchCriteria.partyIds = partyIdss
          setPartyIdsField(partyIdss)
        } else {
          console.log('loading party id from hisotry')
          setPartyIdsField(partyIdsField.value)
        }
        return partyIdss
    }

    function populateStatusCodeAndCount(dat) {
        const data = ApiParser.parseStatusCodeAndCount(dat)
        setPendingCount(data.pendingCount)
        setPendingStatusCountField(data.pendingCount)
        setProcessedStatusCountField(data.processedCount)
        setProcessedCount(data.processedCount)
        console.log(statusField.value)
        setStatus(
          Utils.isEmptyObject(statusField.value)
            ? data.pendingList
            : statusField.value
        )
        setPendingStatusList(data.pendingList)
        setProcessedStatusList(data.processedList)
        console.log(pendingClicked)
        fetchPartyIdFromAPI()
    }

    function fetchStatusCodeAndCountFromAPI() {
        Api.fetchStatusCodeAndCount(
          fetchRecords,
          populateStatusCodeAndCount,
          onError
        )
    }

    function populateSortingType(dat) {
        const list = ApiParser.parseSortingType(dat)
        setSortByList(list)
        setSortByListField(list)
    }

    function getLeftCount() {
        return pendingClicked
          ? pendingCount - shownCount
          : processedCount - shownCount
    }

    function getTotalLeftCount() {
        //total left count should be calculated as (pending count + processed count - shown count)
        //as there could be case when pending count = 0, processed count = 1, and user still need to see the processed count
        // setLeftCount(count)
        return pendingCount + processedCount - shownCount
    }

    function showNoResultPage() {
        return evgmList.length == 0 && getTotalLeftCount() == 0 && !loading
    }

    function getSortingType() {
        console.log('getSortingType')
        Api.fetchSortingType(fetchRecords, populateSortingType, onError)
    }

    function populateEvgmList(data) {
        setEvgmList(data.content)
        setLoading(false)
        setShownCount(data.totalElements)
    }

    function getEvgmList() {
        Api.fetchEvgmList(
          fetchRecords,
          searchCriteria,
          populateEvgmList,
          onError
        )
        setLoadingCriteria(searchCriteria)
    }

    function onError(error) {
        //console.log('connection-request error', error.message)
        setPageError(true)
        setLoading(false)
    }

    const [menuCodeList, setMenuCodeList] = useState([])
    useEffect(() => {
        console.log('get privileges')
    
        fetchRecords.execute(
          `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/user-role/menu-privileges/get`,
          undefined,
          (data) => {
            let temp = []
            data.moduleList.map((item) => {
              item.menuPrivilegeList.map((inner_item) => {
                if (!temp.includes(inner_item.menuCode)) {
                  temp.push(inner_item.menuCode)
                }
              })
            })
            setMenuCodeList(temp)
            console.log('menuList: ' + temp)
          },
          (error) => {
            console.log(error)
          }
        )
    }, [])

    return (
        <>
            {/* <Card className='page-content'> */}
    
            {/* Placeholder image for something went wrong */}
            <Box style={{ display: pageError ? 'inline' : 'none' }}>
                <EvgmListPageError refreshAction = {manualRefresh} />
            </Box>
    
            {/* Placeholder image for no data found */}
            <Box 
                style={{
                    display: showNoResultPage() && !pageError ? 'inline' : 'none'
                }}
            >
                <EvgmListNoResult refreshAction = {manualRefresh} />
            </Box>
    
            {/* Placeholder for summary results and filter panel */}
            <Box
                style={{
                    flexDirection: 'column',
                    display: showNoResultPage() || pageError ? 'none' : 'inline'
                }}
            >
                <Toolbar id='back-to-top-anchor' style={{ minHeight: 0 }} />
    
                <Grid container xs={12} sm={12} spacing={2} justify='center'>
                    <CngGridItem xs={9} sm={9}>
                        <Card>
                            <CardContent>
                                <Grid container xs={12} sm={12}>
                                    <CngGridItem xs={12} sm={12}>
                                        <ButtonTabComponent.FormBody
                                            pendingCount={pendingCount}
                                            processedCount={processedCount}
                                            shownCount={shownCount}
                                            pendingClicked={pendingClicked}
                                            clickPending={clickPending}
                                            clickProcessed={clickProcessed}
                                            sortByList={sortByList}
                                            handleSortByChange={handleSortByChange}
                                        />
                                    </CngGridItem>
                
                                    <CngGridItem xs={12} sm={12}>
                                        <Box class='summary-list-items--container'>
                                            <EvgmListComponent.FormBody
                                                searchCriteria={loadingCriteria}
                                                evgmList={evgmList}
                                                showNotification={showNotification}
                                                handleClearAllButtonClick={
                                                    handleClearFilterAndTextButtonClick
                                                }
                                                leftCount={getLeftCount()}
                                                refreshAction={manualRefresh}
                                                menuCodeList={menuCodeList}
                                                setPageError={setPageError}
                                            />
                                        </Box>
                                    </CngGridItem>
                                </Grid>
                            </CardContent>
                        </Card>
                    </CngGridItem>
        
                    <CngGridItem sm={3} xs={12}>
                        <Grid container xs={12} sm={12} spacing={2}>
                            <CngGridItem xs={12} sm={12}>
                                <FreeTextSearchComponent.FormBody
                                    handleInputChange={handleFilterTextChange}
                                    filterText={filterTextField.value}
                                    handelFreeTextSearch={handelFreeTextSearch}
                                />
                            </CngGridItem>
                            <CngGridItem xs={12} sm={12}>
                                <FilterPanelComponent
                                    handleClearAllButtonClick={handleClearAllButtonClick}
                                    onStatusCheckBoxChange={onStatusCheckBoxChange}
                                    onCarrierCheckBoxChange={onCarrierCheckBoxChange}
                                    dateRange={dateRangeField.value}
                                    carriers={carriers}
                                    status={status}
                                    dateRangeChange={dateRangeChange}
                                />
                            </CngGridItem>
                            <CngGridItem xs={12} sm={9} shouldHide={loading ? false : true}>
                                <CngBackdrop loading={loading} />
                            </CngGridItem>
                        </Grid>
                    </CngGridItem>
                    <ScrollTop>
                        <Fab color='secondary' size='small' aria-label='scroll back to top'>
                            <KeyboardArrowUpIcon />
                        </Fab>
                    </ScrollTop>
                </Grid>
            </Box>
            {/* </Card> */}
      </>
    )
}

const MyEvgmFormProperties = Object.freeze({
    formikProps: FORMIK_PROPS,
    FormFields: FormFields
})

export default MyEvgmFormProperties